$(document).ready(function () {
  var elements = document.querySelectorAll('.js-gallery');
  if(elements && elements.length>0){
    for(var i=0;i<elements.length;i++){
        $(".js-gallery").eq(i).magnificPopup({
            delegate: 'a',
            type: 'image',
            tLoading: 'Ładowanie #%curr%...',
            mainClass: 'mfp-with-zoom',
            tClose: 'Zamknij',
            removalDelay: 400,
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                tPrev: 'Poprzednie',
                tNext: 'Następne',
                preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
                tCounter: '<span class="mfp-counter">%curr% z %total%</span>'
            },
            image: {
                tError: '<a href="%url%">Zdjęcie #%curr%</a> nie może być załadowane.',
                titleSrc: function (item) {
                    return item.el.attr('title');
                }
            }
        });
    }
  }
});
