var newsletter = new function () {

  var me = this;

  this.bindEvents = function () {
    $("[data-form-newslettersubscribe]").bind("submit", function () {
      var form = $(this);
      var params = {
        'email': form.find("input[type='email']").val(),
        'lang': $('html').attr('lang'),
        '_token': window.Laravel.csrfToken
      };
      if ($(this).valid()) {
        $.post("/module/newsletter/subscribe", params, function (response) {
          $("[data-form-newslettermsg]").removeClass('error').removeClass('success');
          if(response.code == 1) {
            $("[data-form-newslettermsg]").html(response.msg);
            $("[data-form-newslettermsg]").addClass('success');
            $("[data-form-newslettersubscribe]").find("[data-form-newsletteremail]").val('');
            setTimeout(function () {
              $("[data-form-newslettermsg]").html('').removeClass('success');
            }, 2000);
          } else {
            $("[data-form-newslettermsg]").html(response.msg);
            $("[data-form-newslettermsg]").addClass('error');
          }
        }, "json");
      }
      return false;
    });
  };
}

$(document).ready(function () {
  newsletter.bindEvents();
});
