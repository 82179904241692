function checkDepend(element){
  var elementId = element.attr('data-formmodule-element');
  if($("[data-formmodule-depend='"+elementId+"']").length>0){
    var dependElement = $("[data-formmodule-depend='"+elementId+"']");
    var inputType = element.prop('tagName').toLowerCase();

    if(inputType!="select")
      inputType = element.attr('type');

    var value = false;
    if(inputType == "select"){
      value = element.find('option:selected').val()!="";
    }else if(inputType=="checkbox" || inputType=="radio"){
      value = element.is(':checked');
    }else{
      value = element.val()!="";
    }

    if(inputType=="select")
      element = element.find('option:selected');

    var dependValues = new Array();
    for(var i=0;i<dependElement.length;i++)
      dependValues = dependValues.concat(dependElement.eq(i).attr('data-formmodule-depend-value').split('|'));

    if(value){
      // console.log(inputType);
      // console.log(dependValues);
      // console.log(dependElement);
      // console.log(element.attr('data-value-id'));
      // console.log(dependElement.attr('data-formmodule-depend-value'));
      // console.log(element.val());


      if(inputType=="radio" || inputType=="select" || inputType=="checked"){
        dependElement.css('display','none');
        dependElement.find('[required]').removeAttr('required');
      }

      if(
        (['select','radio','checkbox'].includes(inputType) && dependValues.includes(element.attr('data-value-id')))
          ||
        (dependElement.attr('data-formmodule-depend-value') === element.val())
      ){
          if(element.attr('data-value-id') === undefined){
            dependElement.css('display','block');
            dependElement.find('[data-required]').each(function(i,el){
              if(typeof $(el).attr('data-required') !== "undefined" && parseInt($(el).attr('data-required'))==1)
                $(el).attr('required','required');
              else
                $(el).removeAttr('required');
            });
          }

          var dependValueElement = $("[data-formmodule-depend-value="+element.attr('data-value-id')+"]");
          if(dependValueElement){
              dependValueElement.css('display','flex');
              dependValueElement.find('[data-required]').each(function(i,el){
                if(typeof $(el).attr('data-required') !== "undefined" && parseInt($(el).attr('data-required'))==1)
                  $(el).attr('required','required');
                else
                  $(el).removeAttr('required');
              });
          }

          var dependValueElement = $("[data-formmodule-depend-value^='"+element.attr('data-value-id')+"|']");
          if(dependValueElement){
              dependValueElement.css('display','flex');
              dependValueElement.find('[data-required]').each(function(i,el){
                if(typeof $(el).attr('data-required') !== "undefined" && parseInt($(el).attr('data-required'))==1)
                  $(el).attr('required','required');
                else
                  $(el).removeAttr('required');
              });
          }

          var dependValueElement = $("[data-formmodule-depend-value*='|"+element.attr('data-value-id')+"|']");
          if(dependValueElement){
              dependValueElement.css('display','flex');
              dependValueElement.find('[data-required]').each(function(i,el){
                if(typeof $(el).attr('data-required') !== "undefined" && parseInt($(el).attr('data-required'))==1)
                  $(el).attr('required','required');
                else
                  $(el).removeAttr('required');
              });
          }

          var dependValueElement = $("[data-formmodule-depend-value$='|"+element.attr('data-value-id')+"']");
          if(dependValueElement){
              dependValueElement.css('display','flex');
              dependValueElement.find('[data-required]').each(function(i,el){
                if(typeof $(el).attr('data-required') !== "undefined" && parseInt($(el).attr('data-required'))==1)
                  $(el).attr('required','required');
                else
                  $(el).removeAttr('required');
              });
          }
      }else{
        if(element.attr('data-value-id') === undefined){
          dependElement.css('display','none');
          dependElement.find('[required]').removeAttr('required');
        }

        var dependValueElement = $("[data-formmodule-depend-value="+element.attr('data-value-id')+"]");
        if(dependValueElement){
          dependValueElement.css('display','none');
          dependValueElement.find('[required]').removeAttr('required');
        }

        var dependValueElement = $("[data-formmodule-depend-value^='"+element.attr('data-value-id')+"|']");
        if(dependValueElement){
          dependValueElement.css('display','none');
          dependValueElement.find('[required]').removeAttr('required');
        }

        var dependValueElement = $("[data-formmodule-depend-value*='|"+element.attr('data-value-id')+"|']");
        if(dependValueElement){
          dependValueElement.css('display','none');
          dependValueElement.find('[required]').removeAttr('required');
        }

        var dependValueElement = $("[data-formmodule-depend-value$='|"+element.attr('data-value-id')+"']");
        if(dependValueElement){
          dependValueElement.css('display','none');
          dependValueElement.find('[required]').removeAttr('required');
        }
      }
    }else{
      var dependValueElement = $("[data-formmodule-depend-value="+element.attr('data-value-id')+"]");
      if(dependValueElement){
        dependValueElement.css('display','none');
        dependValueElement.find('[required]').removeAttr('required');
      }

      var dependValueElement = $("[data-formmodule-depend-value^='"+element.attr('data-value-id')+"|']");
      if(dependValueElement){
        dependValueElement.css('display','none');
        dependValueElement.find('[required]').removeAttr('required');
      }

      var dependValueElement = $("[data-formmodule-depend-value*='|"+element.attr('data-value-id')+"|']");
      if(dependValueElement){
        dependValueElement.css('display','none');
        dependValueElement.find('[required]').removeAttr('required');
      }

      var dependValueElement = $("[data-formmodule-depend-value$='|"+element.attr('data-value-id')+"']");
      if(dependValueElement){
        dependValueElement.css('display','none');
        dependValueElement.find('[required]').removeAttr('required');
      }
    }
  }
}

function checkMsg(element){
  var msg = element.attr('data-choose-msg');
  element.closest('[data-form-row]').find('.formErrors').css('display','none');

  var inputType = element.prop('tagName').toLowerCase();
  if(inputType!="select" && inputType!="option")
    inputType = element.attr('type');
  var used = false, value = "";
  if(inputType == "select" || inputType == "option"){
    used = element.is(':checked')
    value = element.val();
  }else if(inputType=="checkbox" || inputType=="radio"){
    used = $("#"+element.attr('id')).is(':checked');
    value = element.val();
  }else{
    used = true;
    value = element.val();
  }

  if(used && value!=""){
    if(typeof msg !== "undefined" && msg!=""){
      element.closest('[data-form-row]').find('.formErrors li').html(msg);
      element.closest('[data-form-row]').find('.formErrors').css('display','block');
    }

    if(
        (typeof element.attr('data-formmodule-minvalue')!=="undefined" && value!="" && typeof element.attr('data-formmodule-minvaluemsg')!=="undefined" && element.attr('data-formmodule-minvaluemsg')!="")
        ||
        (inputType=="option" && typeof element.closest('select[data-formmodule-element]').attr('data-formmodule-minvalue')!=="undefined" && value!="" && typeof element.closest('select[data-formmodule-element]').attr('data-formmodule-minvaluemsg')!=="undefined" && element.attr('data-formmodule-minvaluemsg')!="")
    ){
      var showMsg = false, messages = typeof element.attr('data-formmodule-minvaluemsg')!=="undefined" ? element.attr('data-formmodule-minvaluemsg').split('|') : [];
      if(inputType=="date" || element.attr('data-formmodule-element-type')=="date"){
        minDates = element.attr('data-formmodule-minvalue').split('|');
        if(value.indexOf('.')!==-1)
          inputValue = value.split('.');
        else
          inputValue = value.split('-');
        inputValue = inputValue[2]+"-"+inputValue[1]+"-"+inputValue[0];
        inputDate = new Date(inputValue);
        inputDate.setHours(0,0,0);
        for(var i=0;i<minDates.length;i++){
          minDate = minDates[i];
          if(minDate.indexOf('.')!==-1)
            minDate = minDate.split('.');
          else
            minDate = minDate.split('-');
          minDate = minDate[2]+"-"+minDate[1]+"-"+minDate[0];
          minDate = new Date(minDate);
          minDate.setHours(0,0,0);
          if(inputDate<minDate){
            msg = messages[i];
            showMsg = true;
            break;
          }
        }
      }else if(inputType=="month"|| element.attr('data-formmodule-element-type')=="month"){
        minDates = element.attr('data-formmodule-minvalue').split('|');
        inputDate = new Date(value+'-01');
        inputDate.setHours(0,0,0);
        for(var i=0;i<minDates.length;i++){
          minDate = minDates[i];
          minDate = minDate.split('-');
          minDate = new Date(minDate);
          minDate.setHours(0,0,0);

          if(inputDate<minDate){
            msg = messages[i];
            showMsg = true;
            break;
          }
        }
      }else if(inputType=="number"){
        mins = element.attr('data-formmodule-minvalue').split('|');
        for(var i=0;i<mins.length;i++){
          if(value<parseFloat(mins[i])){
            msg = messages[i];
            showMsg = true;
            break;
          }
        }
      }else if(inputType=="option"){
        mins = element.closest('select[data-formmodule-element]').attr('data-formmodule-minvalue').split('|');
        for(var i=0;i<mins.length;i++){
         if(value<mins[i]){
           showMsg = true;
           msg = messages[i];
           break;
         }
       }
      }else{
        mins = element.attr('data-formmodule-minvalue').split('|');
        for(var i=0;i<mins.length;i++){
          if(value<mins[i]){
            showMsg = true;
            msg = messages[i];
            break;
          }
        }
      }

      if(showMsg){
        if(typeof msg !== "undefined" && msg!=""){
          element.closest('[data-form-row]').find('.formErrors li').html(msg);
          element.closest('[data-form-row]').find('.formErrors').css('display','block');
        }
      }
    }

    showMsg = false;
    messages = typeof element.attr('data-formmodule-maxvaluemsg')!=="undefined" ? element.attr('data-formmodule-maxvaluemsg').split('|') : [];
    if(
        (typeof element.attr('data-formmodule-maxvalue')!=="undefined" && value!="" && typeof element.attr('data-formmodule-maxvaluemsg')!=="undefined" && element.attr('data-formmodule-maxvaluemsg')!="")
        ||
        (inputType=="option" && typeof element.closest('select[data-formmodule-element]').attr('data-formmodule-maxvalue')!=="undefined" && value!="" && typeof element.closest('select[data-formmodule-element]').attr('data-formmodule-maxvaluemsg')!=="undefined" && element.attr('data-formmodule-maxvaluemsg')!="")
    ){
      var showMsg = false;
      if(inputType=="date" || element.attr('data-formmodule-element-type')=="date"){
        maxDates = element.attr('data-formmodule-maxvalue').split('|');
        if(value.indexOf('.')!==-1)
          inputValue = value.split('.');
        else
          inputValue = value.split('-');
        inputValue = inputValue[2]+"-"+inputValue[1]+"-"+inputValue[0];
        inputDate = new Date(inputValue);
        inputDate.setHours(0,0,0);

        for(var i=0;i<maxDates.length;i++){
          maxDate = maxDates[i];
          maxDate = minDates[i];
          if(maxDate.indexOf('.')!==-1)
            maxDate = maxDate.split('.');
          else
            maxDate = maxDate.split('-');
          maxDate = maxDate[2]+"-"+maxDate[1]+"-"+maxDate[0];

          maxDate = new Date(maxDate);
          maxDate.setHours(0,0,0);

          if(inputDate>maxDate){
            msg = messages[i];
            showMsg = true;
            break;
          }
        }
      }else if(inputType=="month"|| element.attr('data-formmodule-element-type')=="month"){
        maxDates = element.attr('data-formmodule-maxvalue').split('|');
        inputDate = new Date(value+'-01');
        inputDate.setHours(0,0,0);

        for(var i=0;i<maxDates.length;i++){
          maxDate = maxDates[i];
          maxDate = new Date(maxDate);
          maxDate.setHours(0,0,0);
          if(inputDate>maxDate){
            msg = messages[i];
            showMsg = true;
            break;
          }
        }
      }else if(inputType=="number"){
        maxs = element.attr('data-formmodule-maxvalue').split('|');
        for(var i=0;i<maxs.length;i++){
          if(value>parseFloat(maxs[i])){
            msg = messages[i];
            showMsg = true;
            break;
          }
        }
      }else if(inputType=="option"){
        maxs = element.closest('select[data-formmodule-element]').attr('data-formmodule-maxvalue').split('|');
        for(var i=0;i<maxs.length;i++){
         if(value>maxs[i]){
           showMsg = true;
           msg = messages[i];
           break;
         }
       }
      }else{
        maxs = element.attr('data-formmodule-maxvalue').split('|');
        for(var i=0;i<maxs.length;i++){
          if(value>maxs[i]){
            showMsg = true;
            msg = messages[i];
            break;
          }
        }
      }

      if(showMsg){
        if(typeof msg !== "undefined" && msg!=""){
          element.closest('[data-form-row]').find('.formErrors li').html(msg);
          element.closest('[data-form-row]').find('.formErrors').css('display','block');
        }
      }
    }
  }
}

function init(){
  $("input[data-formmodule-element]").trigger('keyup');
  $("select[data-formmodule-element]").trigger('change');
  $("input[data-formmodule-element]").trigger('change');
}

$(document).ready(function(){
  $("select[data-formmodule-element]").bind('change',function(){
    checkDepend($(this));
    checkMsg($(this).find('option:selected'))
  });
  $("select[data-formmodule-element]").selectize({
    render:{
        option: function (data, escape) {
          // console.log(data);
          return "<div data-value-id='"+data.valueId+"' data-choose-msg='"+data.chooseMsg+"' class='option'>"+data.text+" </div>";
        },
        item: function(data) {
          return "<div data-value-id='"+data.valueId+"' data-choose-msg='"+data.chooseMsg+"' class='item'>"+data.text+" </div>";
        }
    },
    onItemAdd: function(value, item) {
      var select = $(item).closest('[data-form-row]').find('select');
      $(select).find('option:selected').attr('value',value);
      $(select).find('option:selected').attr('data-value-id',item.attr('data-value-id'));
      $(select).find('option:selected').attr('data-choose-msg',item.attr('data-choose-msg'));
      checkDepend(select);
      checkMsg(select.find('option:selected'));
    },
  });
  $("input[data-formmodule-element]").bind('click',function(){
    checkDepend($(this));
    checkMsg($(this));
  });
  $("input[data-formmodule-element]").bind('change',function(){
    checkDepend($(this));
    checkMsg($(this));
  });
  $("select[data-formmodule-monthyear],select[data-formmodule-monthmonth]").bind('change',function(){
    var year = $(this).closest("[data-form-row]").find("select[data-formmodule-monthyear]").find('option:selected').val();
    var month = $(this).closest("[data-form-row]").find("select[data-formmodule-monthmonth]").find('option:selected').val();
    $(this).closest("[data-form-row]").find('input[data-formmodule-element]').val(year+"-"+month);
    $(this).closest("[data-form-row]").find('input[data-formmodule-element]').trigger('change');
  });
  // $("input[data-formmodule-element]").bind('keyup',function(){
  //   checkDepend($(this));
  //   checkMsg($(this));
  // });

  init();
});
