var category = new function() {
    var me = this;
    this.containerAmong;
    this.durningRequest = false;

    this.showNext = function(el) {
        var categoryBox = $(el).closest("[data-category-box]");
        var currentPage = parseInt(categoryBox.attr("data-category-currentpage"));
        var container = categoryBox.find("[data-category-container]");
        /*if (container.eq(currentPage).length > 0) {
            container.eq(currentPage).fadeIn();
        }
        if (container.eq(currentPage + 1).length == 0) {
            $(el).fadeOut();
        }
        $(el).closest("[data-category-box]").attr("data-category-currentpage", currentPage + 1);*/
        var data = {
            category_id: categoryBox.data("category-id"),
            page_nr: parseInt(categoryBox.data("category-page")) + 1,
            _token: Laravel.csrfToken,
            language: $('html').attr('lang')
        };

        if(typeof categoryBox.find("[data-category-thumbnail]")!=="undefined" && categoryBox.attr("data-category-thumbnail")!=""){
          data.thumbnail = categoryBox.attr("data-category-thumbnail");
        }

        if(typeof categoryBox.find("[data-module]")!=="undefind"){
          data.module = categoryBox.attr("data-module");
        }

        if(typeof categoryBox.find("[data-listing]")!=="undefind"){
          data.listing = categoryBox.attr("data-listing");
        }

        var requestParams = document.location.href.split('/').pop().split(',');
        if(requestParams.length > 1 && parseInt(requestParams[1])>=1 && parseInt(requestParams[1])<=12)
          data.month = requestParams[1];
        if(requestParams.length == 2)
          data.year = requestParams[1];
        else if(requestParams.length > 2)
          data.year = requestParams[2];

        if(!me.durningRequest)
          me.getPosts(data,categoryBox);
    };

    /**
     * data:
     *  category_id
     *  page_nr
     *  _token
     */
    this.getPosts = function(data,categoryBox){
      me.durningRequest = true;
      $.ajax({
          url: "/module/postfromcategory",
          method: "POST",
          data: data,
          dataType: "json",
          success: function (data) {
              if (data.posts.length == 0) {
                  $("[data-category-loadpage]").remove();
              } else {
                  var template = $("#entry-template").html();
                  var stone = Handlebars.compile(template)(data);
                  var $items = $.parseHTML(stone);
                  if(data.posts.length<parseInt(categoryBox.data('category-perpage')))
                    $("[data-category-loadpage]").remove();

                  categoryBox.find("[data-category-container]").append($items);
                  categoryBox.data('category-page', data.page);
                  $(".loader").data("offset", data.offset);
              }
              me.durningRequest = false;

              if(data.next==0)
                categoryBox.find('div[data-category-loadpage]').addClass('hidden');
          }
      });
    };

    this.bindEvents = function() {
        $("[data-category-loadpage]").bind("click", function() {
            me.showNext($(this));
        });
    };

};

$(document).ready(function () {
    if (document.querySelector('[data-category-loadpage]'))
      category.bindEvents();

    if (document.querySelector('.loader')){
      $('.loader').on('inview', function (event, isInView) {
          if (isInView) {
              var data = {
                  category_id: $("[data-category-id]").data("category-id"),
                  page_nr: parseInt($("[data-category-page]").data("category-page")) + 1,
                  _token: Laravel.csrfToken
              };

              category.getPosts(data);
          }
      });
    }
});

Handlebars.registerHelper('dotdotdot', function(str,length) {
  if (str.length > length)
    return str.substring(0,length-1) + '...';
  return str;
});

Handlebars.registerHelper('compare', function (v1, operator, v2, options) {
   'use strict';
   console.log(options);
   var operators = {
     '==': v1 == v2 ? true : false,
     '===': v1 === v2 ? true : false,
     '!=': v1 != v2 ? true : false,
     '!==': v1 !== v2 ? true : false,
     '>': v1 > v2 ? true : false,
     '>=': v1 >= v2 ? true : false,
     '<': v1 < v2 ? true : false,
     '<=': v1 <= v2 ? true : false,
     '||': v1 || v2 ? true : false,
     '&&': v1 && v2 ? true : false
   }
   if (operators.hasOwnProperty(operator)) {
     if (operators[operator]) {
       return options.fn(this);
     }
     return options.inverse(this);
   }
   return console.error('Error: Expression "' + operator + '" not found');
});

Handlebars.registerHelper('indexof', function(array,index) {
  if(array.length > index)
    return array[index];
  else
    return "";
});

Handlebars.registerHelper('slugify', function(value) {
  return this[value].toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-');
});
