var validator = new function() {
    this.methods = function() {
      if(typeof jQuery.validator !== "undefined"){
        jQuery.validator.addMethod("phone", function(value, element) {
            var slug = /^[0-9\-]+$/;
            return slug.test(value) && value.length == 9;
        }, Laravel.lang.validation.phone);
      }
    };
};

$(document).ready(function() {
    validator.methods();

    $("[data-search-form] input").bind("keyup",function(){
      var value = $(this).val();

      if(value.length>2){
        var currentRequest = null;

        currentRequest = jQuery.ajax({
            type: 'POST',
            data: {
              'phrase':value,
              '_token': window.Laravel.csrfToken,
              'lang': $('html').attr('lang')
            },
            url: '/module/search/hint',
            beforeSend : function()    {
                if(currentRequest != null) {
                    currentRequest.abort();
                }
            },
            success: function(data) {
                $("[data-search-form] ul").html('');
                if(Object.keys(data.results).length>0){
                  for (var prop in data.results) {
                    // for(var item in data.results[prop]){
                      var template = $("#search-hint-template").html();
                      var stone = Handlebars.compile(template)({"results":data.results[prop]});
                      var items = $.parseHTML(stone);
                      $("[data-search-hintcontainer]").append("<li class='katsearch'><h2>"+Laravel.lang.search[prop]+"</h2></li>");
                      $("[data-search-hintcontainer]").append(items);
                    // }
                    $("[data-search-hintcontainer]").css({'display':'block'});
                  }
                }else{
                  $("[data-search-hintcontainer]").css({'display':'none'});
                }
            },
            error:function(e){
              // Error
            }
        });
      }else{
        $("[data-search-form] ul").css({'display':'none'});
      }
    });
});
