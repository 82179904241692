var category = new function() {

    var me = this;

    this.containerAmong;

    /**
     * lewo/prawo
     */
    this.swipeNext = function(type, el) {
        var categoryBox = $(el).closest("[data-category-box]");
        var currentPage = parseInt(categoryBox.attr("data-category-currentpage"));

        categoryBox.find("[data-category-container]").eq(currentPage).fadeOut(function() {
            currentPage += type;

            if (currentPage == 0)
                categoryBox.find("[data-category-swipeleft]").addClass("hidden");
            else
                categoryBox.find("[data-category-swipeleft]").removeClass("hidden");

            if (currentPage == categoryBox.find("[data-category-container]").length - 1)
                categoryBox.find("[data-category-swiperight]").addClass("hidden");
            else
                categoryBox.find("[data-category-swiperight]").removeClass("hidden");

            categoryBox.find("[data-category-container]").fadeOut(500).promise().done(function() {
                categoryBox.find("[data-category-container]").eq(currentPage).fadeIn();
            });

            $(el).closest("[data-category-box]").attr("data-category-currentpage", currentPage);
        });
    };

    /**
     * akcja na kliknięcie przyciku
     */
    this.showNext = function(el) {
        var categoryBox = $(el).closest("[data-category-box]");
        var currentPage = parseInt(categoryBox.attr("data-category-currentpage"));

        var container = categoryBox.find("[data-category-container]");

        if (container.eq(currentPage).length > 0) {
            container.eq(currentPage).fadeIn();
        }
        if (container.eq(currentPage + 1).length == 0) {
            $(el).fadeOut();
        }

        $(el).closest("[data-category-box]").attr("data-category-currentpage", currentPage + 1);
    };

    this.showPageFromPagination = function(el, current) {
        // $(el).fadeOut("slow", function() {
        //     $(el).eq(current - 1).fadeIn();
        // });
        $(el).fadeOut(500).promise().done(function() {
            $(el).eq(current - 1).fadeIn();
        });
    };

    this.drawPagination = function(currentPage, allPages, dest) {
        var displayedPages = 5;
        var page, i;

        dest.html('');

        if (currentPage > displayedPages)
            dest.append("<li><a href='javascript:void(0)' data-category-page='1'>&lt;&lt;</a>");

        if (currentPage > 1)
            dest.append("<li><a href='javascript:void(0)' class='btn btn-default' data-category-page='" + parseInt(currentPage - 1) + "'>&lt;</a></li>");

        for (i = (currentPage - displayedPages - 1 > 0) ? (currentPage - displayedPages - 1) : 1; i < currentPage; i++) {
            dest.append("<li><a href='javascript:void(0)' class='btn btn-default' data-category-page='" + i + "'>" + i + "</a></li>");
        }

        if (allPages > 1) {
            dest.append("<li class='active'><span>" + (currentPage ? currentPage : 1) + "</span></li>");
        }
        var size = (parseInt(currentPage + displayedPages - 1) < allPages) ? parseInt(currentPage + displayedPages - 1) : parseInt(allPages);

        for (i = parseInt(currentPage + 1); i <= size; i++) {
            dest.append("<li><a href='javascript:void(0)' class='btn btn-default' data-category-page='" + i + "'>" + i + "</a></li>");
        }
        if (currentPage < size) {
            dest.append("<li><a href='javascript:void(0)' class='btn btn-default' data-category-page='" + parseInt(currentPage + 1) + "'>&gt;</a></li>");
        }
        if (currentPage + displayedPages - 1 < allPages) {
            dest.append("<li><a href='javascript:void(0)' class='btn btn-default' data-category-page='" + allPages + "'>&gt;&gt;</a></li>");
        }
    };

    this.bindEvents = function() {

        $("[data-category-loadpage]").bind("click", function() {
            me.showNext($(this));
        });

        $("[data-category-swipeleft]").bind("click", function() {
            me.swipeNext(-1, $(this));
        });

        $("[data-category-swiperight]").bind("click", function() {
            me.swipeNext(1, $(this));
        });

        $("[data-category-box]").each(function(i, val) {
            if ($(val).attr('data-category-loadingtype') == 'show_number') {
                var allPages = $(val).find("[data-category-container]").length;
                var dest = $(val).find("[data-category-pagination]");
                me.drawPagination(1, allPages, dest);
            }
        });

        $("[data-category-box]").on("click", "[data-category-page]", function() {
            var current = parseInt($(this).attr("data-category-page"));
            var dest = $(this).closest("[data-category-pagination]");
            var containers = $(this).closest("[data-category-box]").find("[data-category-container]");
            var allPages = parseInt(containers.length);
            me.drawPagination(current, allPages, dest);
            me.showPageFromPagination(containers, current);
            return false;
        });

        // $("[data-category-box='show_swipe'] [data-category-container]").swipe({
        //     swipe: function(event, direction, distance, duration, fingerCount, fingerData) {
        //         var button;
        //         if (direction == 'left' || direction == 'right') {
        //             button = "[data-category-swipe" + (direction == 'right' ? 'left' : 'right') + "]";
        //         }
        //         var allow = !$(this).closest("[data-category-box]").find(button).hasClass("hidden");
        //         $(this).closest("[data-category-box]").find(button).trigger('click');
        //     }
        // });
    };

};

$(document).ready(function() {
    category.bindEvents();
});
