$(document).ready(function(){
    var elements = $("[data-galleryvideo]");
    for(var i=0; i<elements.length;i++){
      $(elements[i]).slick({
          dots: false,
          vertical: true,
          slidesToShow: 4,
          slidesToScroll: 1,
          verticalSwiping: true,
          infinite: false,
      });
    }

    $("[data-videourl]").on("click", function () {
        var el = $(this);
        var url = el.data("videourl");
        $(this).closest('.videoSlider').find('[data-target]').attr("src", url);
    });

    $("[data-videourl]").eq(0).trigger("click");
});
