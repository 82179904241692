$(document).ready(function(){
    var popupLink = $(".hiddenPopup");
    if(popupLink.length == 1){
      var type = popupLink.data("type");

      if(type!=null){
        type = type.replace(/\s*$/,"");

        if(type === "inline") {
            popupLink.magnificPopup({
                mainClass: 'mfp-with-zoom',
                removalDelay: 400,
                items: {
                    src: '#popup',
                    type: type
                }
            });
        } else{
            popupLink.magnificPopup({
                type: 'iframe',
                mainClass: 'mfp-with-zoom',
                removalDelay: 400,
                iframe: {
                    markup: '<div class="mfp-iframe-scaler">' +
                    '<div class="mfp-close"></div>' +
                    '<iframe id="player" class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
                    '</div>',
                    patterns: {
                        youtube: {
                            index: 'youtube.com/',
                            id: 'v=',
                            src: 'h%id%&autoplay=1'
                        }
                    }
                }
            });
        }
      }
    }
});

$(window).on('load',function () {
    $(".hiddenPopup").trigger("click");
});
