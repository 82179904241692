$(document).ready(function () {

		/**
		 * fix passive listenere psi
		 */
		jQuery.event.special.touchstart = {
			setup: function( _, ns, handle ){
		  	this.addEventListener("touchstart", handle, { passive: true });
		  }
		};

		$('.header__rightSearch ul.nav').hide();

	  //AOS.init();
	  //lazy load
	  // const observer = lozad(); // lazy loads elements with default selector as '.lozad'
	  // observer.observe();

		$('.header__right .header__rightSearch--icon').click(function(){
				$('.header__rightSearch ul.nav').slideToggle("slow");
		});
		$('.mobileMenu .header__rightSearch--icon').click(function(){
				$('.mobileMenu .header__rightSearch ul.nav').slideToggle("slow");
		});
		$('.mobileMenu .closeSearch').click(function(){
				$('.mobileMenu .header__rightSearch ul.nav').slideToggle("hide");
		});

		$('a[href^="#"]').on('click', function(event) {
		    var target = $(this.getAttribute('href'));
		    if( target.length ) {
		        event.preventDefault();
		        $('html, body').stop().animate({
		            scrollTop: target.offset().top
		        }, 1000);
		    }
		});

		if (document.querySelector('.landingPage__image')) {
			$(".landingPage__image").on("click","a", function (event) {
					 event.preventDefault();
					 var id  = $(this).attr('href'),
							 top = $(id).offset().top;
					 $('body,html').animate({scrollTop: top}, 994);
			 });
		 }


		var elements = document.querySelectorAll('.mainContainer__offer-single .module');
		if(elements.length>0){
		  elements.forEach(function(el, i) {
		    el.classList.remove('module');
		    el.classList.add('single__module');
		  });
		}

		var elements = document.querySelectorAll('.offerSingleDetails__slider .slick-arrow');
		if(elements.length>0){
		  elements.forEach(function(el, i) {
		    el.innerHTML = '';
		  });
		}

		if (document.querySelector('.offerSingleDetails__slider')) {
		  $('.offerSingleDetails__slider').slick({
		    slidesToShow: 1,
		    slidesToScroll: 1,
		    arrows: true,
		    fade: true,
				lazyLoad: 'ondemand',
		    prevArrow: '<button type="button" aria-label="'+window.Laravel.lang.slider.prev+'" class="slick-arrow slick-prev"></button>',
		    nextArrow: '<button type="button" aria-label="'+window.Laravel.lang.slider.next+'" class="slick-arrow slick-next"></button>',
		    asNavFor: '.offerSingleDetails__nav'
		  });
		  $('.offerSingleDetails__nav').slick({
		    slidesToShow: 4,
		    slidesToScroll: 1,
		    asNavFor: '.offerSingleDetails__slider',
		    focusOnSelect: true,
				lazyLoad: 'ondemand',
				responsive: [
				 {
					 breakpoint: 500,
					 settings: {
						 slidesToShow: 1,
						 slidesToScroll: 1
					 }
				 }
				]
		  });
		}


		if (document.querySelector('.modules__inner.owl-carousel')) {
		  let owl = $('.modules__inner.owl-carousel').owlCarousel({
		    items: 1,
		    nav: false,
		    animateOut: 'fadeOut',
		    animateIn: true,
		    dots: true,
		    autoplay: true,
		    autoplayTimeout: 5000,
		    smartSpeed: 2000,
		    mouseDrag: true,
		    margin: 25,
		    responsive: {
		      1024: {
		        items: 5
		      }
		    },
				lazyload: true,
				onInitialized: addDotButtonText,
		    onResized: addDotButtonText
		  });

			function addDotButtonText() {
		    $('.owl-dot').each(function() {
						let idx = $(this).index() + 1;
		        $(this).attr('aria-label',window.Laravel.lang.slider.goto+' '+idx);
		    });
			}
		}

		function playVideoSlide(slick,currentSlide,mute){
		  var slide = $(slick.$slides.get(currentSlide));
		  if(slide.find('video').length){
				if(slide.find('video')[0].readyState >= 2) {
						if(typeof mute !=="undefined")
				    	slide.find('video')[0].muted = true;
						else
							slide.find('video')[0].muted = false;
				    slide.find('video')[0].play();
				}else{
					slide.find('video')[0].oncanplay = function() {
						if(typeof mute !=="undefined")
							slide.find('video')[0].muted = true;
						else
							slide.find('video')[0].muted = false
						slide.find('video')[0].play();
					}
				}
		  }
		}

		if (document.querySelector('[data-front-slider]')) {
			$('[data-front-slider]').on('init reInit', function(event, slick){
				playVideoSlide(slick,0,1);
			});
		  $('[data-front-slider]').slick({
		    dots: true,
		    infinite: true,
		    autoplay: false,
		    autoplaySpeed: 2000,
		    slidesToShow: 1,
		    slidesToScroll: 1,
		    arrows: true,
		    speed: 1000,
		    fade: true,
		    useTransform: true,
		    // cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
		    adaptiveHeight: true,
				lazyLoad: 'ondemand',
		    appendArrows: $('.sliderHome__nav'),
		    prevArrow: '<button type="button" aria-label="'+window.Laravel.lang.slider.prev+'" class="slick-prev"></button>',
		    nextArrow: '<button type="button" aria-label="'+window.Laravel.lang.slider.next+'" class="slick-next"></button>'
		  });
			$('[data-front-slider]').on('afterChange', function(event, slick, currentSlide) {
				playVideoSlide(slick,currentSlide);
			});
			$('[data-front-slider]').on('beforeChange', function(event, slick, currentSlide,nextSlide) {
				var slide = $(slick.$slides.get(currentSlide));
		    if(slide.find('video').length){
		      slide.find('video')[0].pause();
		    }
			});
		}


	  // var wow = new WOW();
	  // wow.init();

	  var didScroll = false,
	    header = document.querySelector('.header'),
	    body = document.querySelector('html, body'),
	    ms = document.querySelector('body');

	  function scrollTrue() {
	    didScroll = true;
	  }

	  function isOnViewPoint() {
			if(header!==null){
		    if ((body.scrollTop || ms.scrollTop) >= 100) {
		      header.classList.add('short');
		    } else {
		      header.classList.remove('short');
		    }
			}
	  }

	  window.addEventListener('scroll', scrollTrue,{passive: true});
	  document.onscroll = scrollTrue();

	  setInterval(function () {
	    if (didScroll) {
	      didScroll = false;
	      isOnViewPoint();
	    }
	  });

	  $(".js-up").on("click", function () {
	    var body = $("html, body");
	    body.stop().animate({
	      scrollTop: 0
	    }, 1500, 'swing');
	  });
	  $(window).on("scroll", function () {
	    var scroll = $(window).scrollTop();
	    if (scroll > 300) {
	      $(".js-up").addClass("visible");
	    } else {
	      $(".js-up").removeClass("visible");
	    }
	  });

	  if (document.querySelector('.rellax')) {
	    var rellax = new Rellax('.rellax', {
	      center: true
	    });
	  }

	  $(".sliderOwl__arrowDown").on("click", function (e) {
	    e.preventDefault();
	    var body = $("html, body");
	    body.stop().animate({
	      scrollTop: $('.intro').offset().top - 100
	    }, 1500, 'swing');
	  });

	  $('#arrowMap').on('click', function() {
	    var body = $("html, body");
	    body.stop().animate({
	      scrollTop: $('.contactPlaceMap').offset().top - 200
	    }, 1500, 'swing');
	  });

		var hash= window.location.hash
		      var target = $(hash);
		      headerHeight = 120;
					if (target.length) {
						console.log(target.length);
		        $('html,body').stop().animate({
		          scrollTop: target.offset().top - 125 //offsets for fixed header
		        }, 'linear');
		      }

		const observer = lozad(); // lazy loads elements with default selector as '.lozad'
		observer.observe();

		$("[data-filter-submit]").click(function(){
			var filters = $("[data-filter]"),name,value,counter=1,type;
			var url = top.location.href.split(",");
			var checkedFilters = new Array();
			url = url[0]+",1&";
			for(var i=0;i<filters.length;i++){
				id = filters.eq(i).attr('data-filter');
				name = filters.eq(i).attr('data-filter-field');
				type = filters.eq(i).attr('type');
				operator = filters.eq(i).attr('data-filter-operator');
	      if(typeof operator === "undefined") operator = "";

				if (!filterOperators.includes(name+"_"+operator)) {
					value = "";
					if($("[data-filter='"+id+"']").length>1){ //&& type!="number") {
						var subFilters = $("[data-filter-field='"+name+"']");
						for(var j=0;j<subFilters.length;j++){
							if(type=="checkbox"){
								value+= subFilters.eq(j).is(":checked") ? subFilters.eq(j).val()+"," : "";
							}else if(subFilters.eq(j).val()!=""){
								value+= subFilters.eq(j).val()+",";
							}
						}
						if(value[value.length-1] == ","){
							value = value.slice(0,-1);
						}
					}else if(type=="checkbox"){
						value = filters.eq(i).is(":checked") ? filters.eq(i).val() : "";
					}else{
						value = filters.eq(i).val();
						if(value===null)
							value = "";
					}

					if(typeof value!=="undefined" && value!="" && value!=","){
						if(typeof filters.eq(i).attr('data-filter-name')==="undefined"){
							url+= "filter_" + counter + "=" + name + "&value_" + counter + "=" + value;
							if(typeof filters.eq(i).attr('data-filter-operator')!=="undefined"){
								url+= "&operator_" + counter + "=" + filters.eq(i).attr('data-filter-operator');
								filterOperators.push(name+"_"+operator);
							}else{
	              filterOperators.push(name+"_");
	            }
							url+="&";
							counter++;
						}else{
							url+= filters.eq(i).attr('data-filter-name') + "=" + value+"&";
							filterOperators.push(name+"_");
						}
					}

					checkedFilters.push(name);
				}
			}

			var sort = $("[data-filter-sort]").find("option:selected").val();
			if(typeof sort!=="undefined" && sort!=""){
				url+= "sort="+$("[data-filter-sort]").find("option:selected").val()+"&";
				url+= "sort_order="+$("[data-filter-sort]").find("option:selected").attr('data-sort-order')+"&";
			}

			if(url[url.length-1] == "&"){
				url = url.slice(0,-1);
			}

			//console.log(url);
			top.location.href = url;
		});

		// $('.filters [data-filter]').click(function(){
		// 	$("[data-filter-submit]").trigger('click');
		// });

		$("[data-filter-sort]").change(function(){
			$("[data-filter-submit]").trigger('click');
		});

		$("[data-filter-clear]").click(function(){
			var url = top.location.href.split(",");
			top.location.href = url[0];
		});
});
