$(document).ready(function () {
  $('.js-openMenu').on("click", function () {
    $('.mobileMenu').addClass('opened');
  });

  $('.js-close').on("click", function () {
    $('.mobileMenu').removeClass('opened');
  });

  if(document.querySelectorAll('[data-menu]').length>0){
    $("[data-menu]").on("click", function (e) {
      if (!$(this).hasClass('actived')) {
        e.preventDefault();
        var el = $(this);
        el.addClass("actived");
        el.next('ul').addClass("opened");
        el.next('ul').before("<span class='js-prev mobilePrev'></span>");

        el.closest(".opened").closest("li").children("[data-menu]").hide();
        el.closest(".opened").closest("li").children("[data-menu]").next(".js-prev").hide();
      }
  			console.log('test');
      $('.js-prev').on("click", function () {
        var el = $(this);
  			console.log('test2');

        el.parent("li").children(".opened").removeClass("opened");
        el.parent("li").children(".actived").removeClass("actived");

        el.closest(".opened").parent("li").children("[data-menu]").show();
        el.closest(".opened").parent("li").children(".js-prev").show();
        el.parent("li").children(".js-prev").remove();
      });

    });
  }

});
