var message = new function() {
    this.show = function(type, message) {
        $.notify({
            message: message
        }, {
            type: type,
            placement: {
                from: "bottom",
                align: "right"
            },
            delay: 5000
        });
    };
};

var form = new function() {
    this.validate = function() {
      if(typeof $("[data-formvalidation]").validate != "undefined"){
        $("[data-formvalidation]").bind('submit',function(){
          var langs = $("[data-metagenerator-trigger]").length;
          if(langs>0){
            var elements = document.querySelectorAll("[data-metagenerator-trigger]");
            var hasValue = false;
            var errorMsg = "";
            var langId;
            if(elements.length>0)
              var parent = $("[data-metagenerator-trigger]").eq(i).closest(".tab-pane");
            for(var i=0;i<elements.length;i++){
              if(elements[i].getAttribute('disabled') === "disabled"){

              }else{
                langId = elements[i].getAttribute('data-lang-id');
                errorMsg+= elements[i].getAttribute('data-formvalidation-label') + ', ';
                if(elements[i].value!=""){
                  hasValue = true;
                  parent.find("input[name='title["+langId+"]']").addClass('requried').attr('required','required');
                  parent.find("input[name='name["+langId+"]']").addClass('requried').attr('required','required');
                  parent.find("input[name='url["+langId+"]']").addClass('requried').attr('required','required');
                }else{
                  parent.find("input[name='title["+langId+"]']").removeClass('requried').removeAttr('required');
                  parent.find("input[name='name["+langId+"]']").removeClass('requried').removeAttr('required');
                  parent.find("input[name='url["+langId+"]']").removeClass('requried').removeAttr('required');
                }
              }
            }

            if(!hasValue){
              message.show("danger",window.Laravel.lang.validation.insert_one_of+': '+errorMsg.trim().slice(0,-1));
              return false;
            }else{
              return true;
            }
          }else{
            return true;
          }
        });
        $("[data-formvalidation]").validate({
            "ignore": [],
            "errorPlacement": function(error, element) {
                console.log(element[0].tagName);
                if (typeof element.attr("data-lang-input") !="undefined" && element.is(":hidden")){
                  //nie pokazujemy komunikatów dla ukrytych langów
                }else if (typeof element.attr("type") != "undefined" && element.attr("type") == "radio") {
                    error.insertAfter(element.parent().find('label'));
                }else if (typeof element.attr("type") != "undefined" && element.attr("type") == "file") {
                    error.insertAfter(element.parent());
                }else if(element[0].tagName.toLowerCase()=="select"){
                    error.insertAfter(element.parent().find(".select2:last"));
                } else {
                    error.insertAfter(element);
                }
            },
            "invalidHandler": function(form, validator) {
                var errors = validator.numberOfInvalids();
                if (errors) {
                    var response = Laravel.lang.validation.invalid, msg;
                    if (validator.errorList.length > 0 && $(this).find("[data-formvalidation-label]").length > 0) {
                        response+= ":<ul>";
                        for (x = 0; x < validator.errorList.length; x++) {
                            msg = validator.errorList[x].message;
                            response+= "<li><b>"+$(validator.errorList[x].element).attr("data-formvalidation-label")+"</b> - "+msg[0].toLowerCase()+msg.slice(1,msg.length)+"</li>";
                        }
                        response+= "</ul>";
                    }

                    message.show('danger',response);
                }
            },
            "submitHandler": function(form){
              if(typeof $(form).attr("data-form-beforesubmit") != "undefined"){
                var functionName = $(form).attr("data-form-beforesubmit").split(".");
                var functionMethod = window;
                for(var i=0; i<functionName.length;i++){
                  functionMethod = functionMethod[functionName[i]];
                }

                return functionMethod();
              }
              return true;
            }
        });
      }
    };

    this.bind = function() {
        this.validate();
    };
};

$(document).ready(function() {
    if (document.querySelector('form'))
      form.bind();

    if(typeof $(".verticalcarousel").owlCarousel !== "undefined" && $(".verticalcarousel").length > 0){
      $(".verticalcarousel").owlCarousel({
          //loop: true,
          items: 1,
          nav: true,
          animateOut: 'fadeOut'
      });
    }
});
