$().ready(function () {
    $(".rodo").css("display","none");
    var cookieRodo = getCookie('rodo-cookie');
    if (cookieRodo === 'null'
        || cookieRodo === ""
        || cookieRodo === null
        || cookieRodo === undefined
        || cookieRodo === 'undefined') {
            $(".rodo").css("display","block");
    } else {
        $(".rodo").css("display","none");
    }

});

function closeRodoModalAndSaveCookie() {
    setCookie('rodo-cookie', 1, 30);
    $(".rodo").css("display","none");
}

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
